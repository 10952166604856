import React, { useState } from "react";
import DropdownValue from "./DropdownValue";

interface IDropdownProps {
  type?: string,
  label?: string, 
  value?: string | number | undefined, 
  placeholder?: string, 
  options: any[], 
  onOptionSelect: Function, 
  className?: string,
  containerClassName?: string,
  fullWidth?: boolean,
  bottomReached?: Function,
  clearable?: boolean,
};

const DropDown = ({ 
    type,
    label,
    value,
    placeholder,
    options,
    onOptionSelect,
    className,
    containerClassName,
    fullWidth,
    bottomReached,
    clearable,
  }: IDropdownProps) => {
  const [isActive, setActive] = useState(false);
  const dropdownRef = React.useRef(null);
  const name = label ? label.replace(/ /g,"_") : "";
  const typeClass = type === "line" ? "border-b" : "border rounded-md hover:shadow-lg";

  const toggleMenu = (e) => {
    setActive(!isActive);
  }

  const handleBlur = () => {
    if (dropdownRef.current && !dropdownRef.current.contains(document)){
      setActive(false);
    }
    
  }

  const handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom && bottomReached) {
      bottomReached();
    }
  }

  const clearSelection = (e) => {
    e.stopPropagation();
    onOptionSelect(undefined);
  }

  return (
    <div 
      className={`relative outline-none ${containerClassName} ${fullWidth ?? 'w-full'}`} 
      onBlur={handleBlur}
      key={value}
    >
      {(
        label &&
        <label 
          className="text-sm pl-1"
          htmlFor={ name }
        >
          { label }
        </label>
      )}
      <div 
        className={`transition-all p-2 ${ typeClass } hover:border-red text-base h-8 flex items-center cursor-pointer ${className}`} 
        onClick={toggleMenu}
        tabIndex={0}
        role="button"
        onKeyDown={(e) => {
          if(e.key === 'Enter' || e.key === ' ') {
            toggleMenu(e);
          }
        }}
        aria-label="Dropdown"
      >
        <DropdownValue value={value} placeholder={placeholder} />
        {(clearable && 
          <svg className="mr-2 transform transition duration-50 hover:scale-150" xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="none" viewBox="0 0 24 24" stroke="currentColor" onClick={(e) => clearSelection(e)}>
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"/>
          </svg>
        )}
        <svg className={`transition-all transform ${isActive ? "rotate-180" : "rotate-0"} ${clearable ? "transform transition duration-50 hover:scale-150" : ""}`} xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
        </svg>
      </div>
      <div className={`origin-top-right absolute left-0 mt-2 w-full max-h-64 rounded-md shadow-lg overflow-y-scroll overflow-x-hidden no-scrollbar bg-white z-50 ${isActive ? "block" : "hidden"}`} onScroll={handleScroll}>
        {
          options &&
          options.map((option) => {
            return (
              <div 
                className="px-3 py-1 cursor-pointer hover:bg-red hover:text-white first:pt-2"
                key={option.value || option} 
                onClick={(_) => {
                    onOptionSelect(option)
                    setActive(false)
                  }
                }
                tabIndex={0}
                role="button"
                onKeyDown={(e) => {
                  if(e.key === 'Enter') {
                    onOptionSelect(option)
                    setActive(false)
                  }
                }}
              >
                  {option.label || option}
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

export default React.memo(DropDown);
