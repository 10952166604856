import React, { useEffect, useState } from 'react';
import { useRouter } from "next/router";
import LinesEllipsis from 'react-lines-ellipsis';
import Highlighter from "react-highlight-words";
import Button from '../Buttons/Button';
import DropDown from '../Inputs/DropDown';
import TextField from '../Inputs/TextField';
import Loading from '../Loading';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { getAutoCompleteResults, searchResultsPreview } from '../../../lib/wineRequests';
import { removeHTML } from '../../../utils/helper';
import { useArticlesContext, searchArticles } from '../../../context/articlesContext';

const SearchBar = (props: {
  className?: string,
  hideResults?: boolean,
  onSearch?: Function,
  value?: string,
  type?: string,
  onChange?: Function
  autoComplete?: boolean
}) => {
  const { width } = useWindowDimensions();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [term, setTerm] = useState(props.value || "");
  const [type, setType] = useState(props.type || "Reviews");
  const [active, setActive] = useState(false);
  const [autoFillOptions, setAutoFillOptions] = useState([])
  const router = useRouter();
  const { dispatch: articlesDispatch } = useArticlesContext();

  const handleBlur = () => {
    // Only blur if not on mobile
    if(width > 640) {
      setActive(false);
    }
  }

  const handleFocus = () => {
    setActive(true);
  }

  const handleSearch = async (val) => {
    props.autoComplete && handleAutocomplete(val);
    if(val && !props.hideResults) {
      setLoading(false);
      setActive(true);

      const response = await searchResultsPreview(val);
      setData(response.data);
    }
  }

  const handleAutocomplete = async (val: string) => {
    if (type == "Reviews" && val && val.length !== 0) {
      const response = await getAutoCompleteResults(encodeURIComponent(val))
      if (response.status === 200) {
        setAutoFillOptions([...response.data])
      }
    }
  }

  const handleChange = (e) => {
    if (e?.target?.value.length === 0) setAutoFillOptions([]);
    if(e && e.target) {
      const val = e.target.value;
      setData([]);
      setTerm(val);
      if (val.length < 2) {
        setActive(false);
        setLoading(false);
      } else {
        setLoading(true);
      }
    }
    if (props.onChange) {
      props.onChange(e);
    }
  }

  const handleEnter = (selectedOption?: {label: string}) => {
    if (type === "Reviews") {
      const searchTerm = selectedOption?.label || term;
      if(router.pathname.includes("search") && props.onSearch) {
        props.onSearch(searchTerm);
      } else {
        const termQuery = searchTerm ? "?term=" + searchTerm : "";
        router.push("/wines" + termQuery);
      }
    } else if (type === "Articles") {
      if(router.pathname.includes("articles") && props.onSearch) {
        props.onSearch(term);
      } else {
        searchArticles(articlesDispatch, { search_param: term }).then(() => router.push("/articles?term=" + term));
      }
    } else if (type === "Videos") {
      if(router.pathname.includes("multimedia") && props.onSearch) {
        props.onSearch(term);
      } else {
        router.push("/multimedia?term=" + term);
      }
    }
  }

  useEffect(() => {
    const timeOutId = setTimeout(() => handleSearch(term), 500);
    return () => clearTimeout(timeOutId);
  }, [term, props.value]);

  useEffect(() => {
    if (props.value && props.value !== term) setTerm(props.value);
  }, [props.value]);

  return (
    <div
      className={`relative outline-none z-20 ${props.className}`}
      onBlur={handleBlur}
    >
      <div className="flex justify-end items-center z-40">
        <DropDown
          fullWidth={false}
          value={type}
          options={["Reviews", "Articles", "Videos"]}
          className="rounded-r-none w-28 h-10 p-3"
          onOptionSelect={(val) => {
            setType(val);
          }}
        />
        <TextField
          className="border-r-0 rounded-r-none rounded-l-none w-full"
          placeholder="Search for..."
          type="search"
          value={term}
          onFocus={handleFocus}
          onEnter={handleEnter}
          onChange={handleChange}
          
        />
        <Button
          type="primary"
          className="rounded-l-none"
          icon={
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="48" fill="none" viewBox="0 0 32 32" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
            </svg>
          }
          onClick={(_) => {
            handleEnter()
          }}
        />
      </div>
      {
        !props.hideResults && type === "Reviews" &&
        <div
          className={`origin-top-right absolute left-0 mt-2 w-full rounded-md shadow-lg overflow-y-scroll bg-white max-h-96 ${loading || data.length ? "block" : "hidden"}`}
        >
          <Loading isLoading={loading} />
          {
            (data.length > 0 && active) &&
            data.map((item, index) => {
              return (
                <div
                  className="flex px-3 py-2 cursor-pointer hover:bg-red hover:text-white first:pt-2 border-b last:border-b-0"
                  key={item.label[0] + index}
                  onMouseDown={(e) => {
                    e.preventDefault();
                    setActive(false);
                  }}
                  onKeyDown={(e) => {
                    if(e.key === "Enter") {
                      handleEnter(item);
                    }
                  }}
                  role="button"
                  tabIndex={0}
                >
                  <div className="pr-4 w-full">
                    <Highlighter
                      highlightClassName="bg-yellow"
                      searchWords={[term]}
                      textToHighlight={item.label[0]}
                    />
                    <div className="opacity-60">
                      <div className="font-normal text-sm flex space-x-4">
                        <div>
                          Score: {item.wine_score}
                        </div>
                        <div>
                          Producer: {item.producer[0]}
                        </div>
                        <div>
                          Drinking Window: {item.drinking_window_begin || "N/A"} - {item.drinking_window_end || "N/A"}
                        </div>
                      </div>
                      <LinesEllipsis
                        className="text-base font-light"
                        maxLine="2"
                        ellipsis="..."
                        trimRight
                        text={removeHTML(item.tasting_note)}
                     />
                    </div>
                  </div>
                  <div className="opacity-40 self-center w-4">
                    <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                    </svg>
                  </div>
                </div>
              )
            })
          }
        </div>
      }
      {autoFillOptions.length > 0 && props.autoComplete && type === "Reviews" && (
            <div className="w-full absolute top-10 max-h-72 rounded-t-none rounded bg-white border-gray-200 border border-t-0 shadow-lg grid overflow-scroll">
              {autoFillOptions.map((option, i) => {
                return (
                  <div
                    key={i}
                    className="p-2 w-full hover:bg-gray-200 cursor-pointer "
                    onMouseDown={() => handleEnter(option)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleEnter(option)
                      }
                    }}
                    role="button"
                    tabIndex={0}
                  >
                    {option.label}
                  </div>
                )
              })}
            </div>
          )}
    </div>
  );
}

export default SearchBar;
