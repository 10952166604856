import React, { memo } from 'react';

const DropdownValue = ({ value, placeholder }) => {
  if (!!value) {
    return (
      <div className="pr-2 w-full text-base">
        { value }
      </div>
    );
  } else {
    return (
      <div className="pr-2 w-full text-gray-500 font-light">
        { placeholder }
      </div> 
    )
  }
}

const MemoDropdownValue = memo(DropdownValue);
export default MemoDropdownValue;
